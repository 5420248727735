import React from 'react';
import ListCustom from '../../Components/ListCustom/ListCustom';
import { DateField, ReferenceArrayInput, TextField, TextInput } from 'react-admin';
import ListActions from '../../Components/ListActions/ListActions';
import InputAutocomplete from '../../Components/InputsForm/InputAutocomplete';

const UsersList = () => {

  const usersFilters = [
    <TextInput label="Search" source="q" alwaysOn variant='outlined' size='small' />,
    <ReferenceArrayInput alwaysOn source="favourite_talents" reference='talents'>
      <InputAutocomplete isArray variant='outlined' optionText="name" fullWidth />
    </ReferenceArrayInput>,
    <ReferenceArrayInput alwaysOn source="favourite_events" reference='events' filter={{ type: 'show' }}>
      <InputAutocomplete isArray variant='outlined' optionText="name" fullWidth />
    </ReferenceArrayInput>
  ];

  const paramsList = {
    listParams: {
      filters: usersFilters,
      actions: <ListActions />
    },
    datagridParams: {
      bulkActionButtons: false,
      rowClick: 'edit'
    }
  };

  return (
    <ListCustom {...paramsList}>
      <TextField source="id" />
      <TextField source="expo_token" />
      <DateField source="date_created" />
      <TextField source="app_version" />
    </ListCustom>
  );
};

export default UsersList;