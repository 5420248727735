import { defaultTheme } from 'react-admin';
import { COLORS } from '../assets/constants/theme';


const theme = {
  typography: {
    ...defaultTheme.typography,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    ...defaultTheme.components,
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontSize: '.9rem',
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px'
        },
        input: {
          paddingTop: '18px',
          paddingRight: '3px',
          paddingBottom: '3px',
        }
      }
    },
    RaAutocompleteInput: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            paddingTop: '15px'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '.9rem',
        }
      }
    }
  }
};

export const lightTheme = {
  ...defaultTheme,
  palette:{
    mode: 'light',
    ...COLORS.light
  },
  ...theme
};

export const darkTheme = {
  ...defaultTheme,
  palette:{
    mode: 'dark',
    ...COLORS.dark
  },
  ...theme
};