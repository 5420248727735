import React from 'react';
import { DateField, TextField } from 'react-admin';
import ListCustom from '../../Components/ListCustom/ListCustom';
import ListActions from '../../Components/ListActions/ListActions';

const paramsList = {
  listParams: {
    hasCreate: true,
    actions: <ListActions />
  },
  datagridParams: {
    bulkActionButtons: false,
    rowClick: 'edit'
  }
};

const VenuesList = () => {
  return (
    <ListCustom {...paramsList}>
      <TextField source="name" />
      <TextField source="type" />
      <DateField source="last_modified" />
    </ListCustom>
  );
};

export default VenuesList;