import { useResourceContext } from 'react-admin';
import S3 from 'react-aws-s3';
window.Buffer = window.Buffer || require('buffer').Buffer;

const useUploadAws = () => {
  const resource = useResourceContext();

  const configAWS = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    dirName: resource ? `originals/${resource}` : 'nodirName',
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY
  };

  const uploadAWS = async (e) => {
    let file = e.target.files[0];
    let fileName = e.target.files[0].name;
    const reactS3Client = new S3(configAWS);
    const nameTrim = fileName?.replace(/[^a-zA-Z0-9]/g, '');
    const dataFile = await reactS3Client.uploadFile(file, nameTrim).then(data => {
      return ({...data, success: true});
    }).catch(e => {
      return ({ ...e, success: false});
    });
    return dataFile;
  };

  return uploadAWS;
};

export default useUploadAws;