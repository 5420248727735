import EventsCreate from './Form/CreateEvents';
import EventsEdit from './Form/EditEvents';
import EventsList from './EventsList';
import EventsShow from './EventsShow';
import { TbMicrophone2 } from 'react-icons/tb';

export default {
  list: EventsList,
  edit: EventsEdit,
  create: EventsCreate,
  icon: TbMicrophone2
};