import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDelete, useGetOne, useRedirect, useUpdate } from 'react-admin';
import { MdOutlineModeEdit, MdClose, MdOutlineDelete, MdCalendarToday, MdLocationOn, MdTag } from 'react-icons/md';
import { BiCalendarX } from 'react-icons/bi';
import { TbMicrophone2 } from 'react-icons/tb';
import { stage_color } from '../../assets/constants/Calendar';
import moment from 'moment';
import { COLORS } from '../../assets/constants/theme';
import { defaultKeys } from '../../assets/constants/Calendar';
import ConfirmCustom from '../ConfirmCustom/ConfirmCustom';

const colorIcons = 'rgba(0,0,0, .55)';

const ModalItemClicked = ({ itemClicked, handleClose, timelineContext }) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmUnschedule, setOpenConfirmUnschedule] = useState(false);
  const redirect = useRedirect();
  const [update, { isLoading: isLoadingUpdate, error: errorUpdate }] = useUpdate();
  const [deleteOne, { isLoading: isLoadingDelete, error: errorDelete }] = useDelete();
  // const showModalLeft = moment(itemClicked.start_date).diff(moment(timelineContext.visibleTimeStart), 'minutes') > 220;


  const handleEdit = () => {
    redirect(`/events/${itemClicked.id}`);
  };

  const handleUnschedule = () => {
    const data = { ...itemClicked, [defaultKeys['itemGroupKey']]: null, start_date: null, end_date: null };
    update('events', { id: itemClicked.id, data });
  };

  const handleDelete = () => {
    deleteOne('events', { id: itemClicked.id });
  };

  return (
    <Box sx={{
      cursor: 'default',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      zIndex: 999,
      position: 'relative',
      // right: showModalLeft && 0,
      // left: !showModalLeft && 0,
      left: 0,
      top: 3,
      width: 420,
      backgroundColor: '#fff',
      borderRadius: 1,
      padding: 1
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Tooltip title="Delete">
          <IconButton size='small' onClick={() => setOpenConfirm(true)}>
            <MdOutlineDelete color={'#ed4747'} fontSize={24} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Unschedule">
          <IconButton size='small' onClick={() => setOpenConfirmUnschedule(true)}>
            <BiCalendarX color={COLORS.light.secondary.main} fontSize={24} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton size='small' onClick={handleEdit}>
            <MdOutlineModeEdit color={colorIcons} fontSize={24} />
          </IconButton>
        </Tooltip>
        {/* <IconButton size='small' onClick={handleClose}>
          <MdClose color={colorIcons} fontSize={24} />
        </IconButton> */}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', paddingX: 1, paddingY: 0, gap: '5px', overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', paddingBottom: '3px' }}>
          <Box sx={{ width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ width: 21, height: 21, borderRadius: 1, backgroundColor: stage_color[itemClicked.stageFormat]?.light }}></Box>
          </Box>
          <Typography variant='h6' color='#000' sx={{ lineHeight: '24px' }}>{itemClicked.name}</Typography>
        </Box>
        {
          itemClicked.talents?.length > 0 && itemClicked.talents[0]?.name?.length > 0 && (
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <Box sx={{ width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <TbMicrophone2 color={colorIcons} fontSize={18} />
              </Box>
              <Typography variant='subtitle2' color={colorIcons} sx={{ lineHeight: '24px' }}>{itemClicked.talents[0]?.name}</Typography>
            </Box>
          )
        }
        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
          <Box sx={{ width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <MdCalendarToday color={colorIcons} fontSize={18} />
          </Box>
          <Typography variant='body2' color={colorIcons}>{`${moment(itemClicked.start_date).format('dddd, MMMM DD ⋅ HH:mm')} - ${moment(itemClicked.end_date).format('HH:mm')}`}</Typography>
        </Box>
        {
          itemClicked.stage?.name && (
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <Box sx={{ width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <MdLocationOn color={colorIcons} fontSize={18} />
              </Box>
              <Typography variant='body2' color={colorIcons}>{` ${itemClicked.stage?.name}`}</Typography>
            </Box>
          )
        }
        {
          itemClicked?.tags?.length > 0 && (
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <Box sx={{ width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <MdTag color={colorIcons} fontSize={16} />
              </Box>
              <Box sx={{ display: 'flex' }}>
                {
                  itemClicked.tags.map((i, index) =>
                    <Typography variant='subtitle2' color={colorIcons}>
                      {i}{index < itemClicked.tags.length - 1 ? ', ' : ' '}
                    </Typography>
                  )
                }
              </Box>
            </Box>
          )
        }
      </Box>
      <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', justifyContent: 'flex-end', padding: '6px 6px 0 0' }}>
        <Typography variant='body2' color={colorIcons} fontSize={12}>
          Last modified {moment(itemClicked.last_modified).format('ddd MMMM DD ⋅ HH:mm')}
        </Typography>
      </Box>
      <ConfirmCustom
        isOpen={openConfirm}
        loading={isLoadingDelete}
        onClose={() => setOpenConfirm(false)}
        onConfirm={handleDelete}
        title={`Delete event "${itemClicked.name}"`}
        content="Are you sure you want to delete this event?"
      />
      <ConfirmCustom
        isOpen={openConfirmUnschedule}
        loading={isLoadingUpdate}
        onClose={() => setOpenConfirmUnschedule(false)}
        onConfirm={handleUnschedule}
        title={`Unschedule event "${itemClicked.name}"`}
        content="Are you sure you want to unschedule this event?"
      />
    </Box>
  );
};

export default ModalItemClicked;