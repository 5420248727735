import React, { useEffect, useState } from 'react';
import { BooleanInput, DateField, Filter, FunctionField, ListBase, ReferenceField, TextField, TextInput, useGetList, useListContext, useSidebarState, useStore } from 'react-admin';
import { Box, Button, Switch } from '@mui/material';
import CalendarTimeLine from '../../Components/CalendarTimeLine/CalendarTimeLine';
import ListCustom from '../../Components/ListCustom/ListCustom';
import { MdCalendarToday } from 'react-icons/md';
import ListActions from '../../Components/ListActions/ListActions';
import { convertTime } from '../../helpers/date';
import FilterButtons from '../../Components/CalendarTimeLine/FilterButtons';
import moment from 'moment';
import { HOURS, MINUTES } from '../../Components/InputsForm/InputDuration';

const EventsList = ({ ...props }) => {
  const [viewCalendar, setViewCalendar] = useStore('viewCalendar', true);

  useEffect(() => {
    setViewCalendar(false);
  }, []);

  return (
    <ListBase perPage={9999} sort={{ field: 'start_date', order: 'DESC' }} {...props}>
      {
        viewCalendar ? <CalendarBase /> : <ListView />
      }
    </ListBase>
  );
};

const CalendarBase = () => {
  const { data, isLoading } = useListContext();
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useSidebarState();
  const [mutatedVenues, setMutatedVenues] = useState(null);
  const [filter, setFilter] = useState('');
  const { data: venues, isLoading: loadingVenues } = useGetList('venues', { pagination: {page: 1, perPage: 1000}});

  const keys = {
    groupIdKey: 'id',
    groupTitleKey: 'name',
    itemIdKey: 'id',
    itemTitleKey: 'name',    // key for item div content
    itemDivTitleKey: 'name', // key for item div title (<div title="text"/>)
    itemGroupKey: 'stageFormat',
    itemTimeStartKey: 'start_date',
    itemTimeEndKey: 'end_date',
  };

  function loopedVenues (venues) {
    let arr = [];
    for(let i = 0; i < venues?.length; i++) {
      let obj = venues[i];
      obj.stackItems = true;
      arr.push(obj);
      continue;
    }
    return arr;
  }
  useEffect(() => {
    if (venues?.length > 0){
      setMutatedVenues(loopedVenues(venues));
    }
    return () => {
      setMutatedVenues([]);
    };
  },[venues]);

  useEffect(() => {
    if(filter?.venues?.length > 0){
      let filterArr = [];
      venues?.map(item => filter?.venues?.map(fil => fil === item.id && filterArr.push(item)));
      setMutatedVenues(filterArr);
    } else {
      setMutatedVenues(loopedVenues(venues));
    }

    if(filter?.tags?.length > 0){
      let filterArr = [];
      data?.map(item => item?.tags?.map(tag => filter?.tags?.map(fil => fil === tag && filterArr.push(item))));
      setEvents(filterArr);
    } else {
      setEvents(data);
    }
  },[filter]);

  if (!loadingVenues && !isLoading) {
    return (
      <Box sx={{ width: open ? 'calc(100vw - 274px)' : 'calc(100vw - 83px)' }}>
        {
          data && mutatedVenues && (
            <>
              <ListActionsToggle hasCreate setFilter={setFilter} venues={venues} />
              <CalendarTimeLine items={events && events?.filter(item => item.start_date !== null && item.end_date !== null)} secondaryItems={data.filter(i => i.start_date == null)} groups={mutatedVenues} keys={keys} />
            </>
          )
        }
      </Box>
    );
  }
};


const ListView = () => {

  const eventsFilters = [
    <TextInput label="Search" source="q" alwaysOn variant='outlined' />,
    <BooleanInput alwaysOn source="scheduled" label="Scheduled events"  />
  ];

  const paramsList = {
    listParams: {
      hasCreate: true,
      filterDefaultValues: {'scheduled' : true},
      filters: eventsFilters,
      actions: <ListActionsToggle hasCreate />
    },
    datagridParams: {
      bulkActionButtons: false,
      rowClick: 'edit'
    }
  };

  return (
    <ListCustom {...paramsList}>
      {/* <ImageField
        source="picture.original"
        label=''
        sx={{ '& img': { borderRadius: 200, maxWidth: 39, maxHeight: 39, objectFit: 'cover' } }}
      /> */}
      <TextField source="name" />
      <ReferenceField source="stage.id" label='Stage' reference="venues">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="type" />
      <FunctionField source="duration" render={(e) => `${HOURS[Math.floor(e?.duration / 60)]}:${MINUTES[Math.ceil(e?.duration % 60)]} hs`} />
      <FunctionField label="Start date" render={(e) => moment(convertTime(e?.start_date)).format('DD/MM/YYYY - hh:mm:ss A')} />
      <FunctionField label="End date" render={(e) => !moment(e?.end_date).isValid() ? '-' : moment(convertTime(e?.end_date)).format('DD/MM/YYYY - hh:mm:ss A')} />
    </ListCustom>
  );
};

const ListActionsToggle = ({ setFilter, venues, ...rest }) => {
  const [viewCalendar, setViewCalendar] = useStore('viewCalendar');

  return (
    <ListActions {...rest}>
      {
        viewCalendar && (
          <FilterButtons setFilter={setFilter} groups={venues} />
        )
      }
      <Button
        startIcon={<MdCalendarToday style={{ width: '15px' }} />}
        onClick={() => setViewCalendar(!viewCalendar)}
        size='small'
        sx={{ padding: '3px', display: 'flex', alignItems: 'center' }}
      >
        {
          viewCalendar ? 'View List' : 'View Calendar'
        }
      </Button>
      
    </ListActions>
  );
};

export default EventsList;