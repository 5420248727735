import React from 'react';
import { BooleanInput } from 'react-admin';

const InputBoolean = ({ ...rest }) => {
  return (
    <BooleanInput
      size='small'
      {...rest}
    />
  );
};

export default InputBoolean;