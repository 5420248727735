import React, { useContext, useEffect, useState } from 'react';
import { Menu, MenuItemLink, useResourceDefinitions, useSidebarState } from 'react-admin';
import { Collapse, Grow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Box, Divider, Fade } from '@mui/material';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { COLORS } from '../../assets/constants/theme';

const MenuCustom = (props) => {
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);
  const sidebarOpen = useSidebarState();
  return (
    <Menu {...props}>
      <Box
        sx={{
          height: '63vh',
          overflow: 'scroll',
          // borderBottom: '1px solid ' + grey[300],
          '&::-webkit-scrollbar': {
            width: '1px',
            borderRadius: '12px',
          }
        }}
      >
        {
          resources.map(resource => {
            if (resource.options?.isMenuParent) {
              return (
                <SectionParent key={resource.name} resource={resource} resources={resources} />
              );
            } else {
              if (resource.hasList && !resource.options?.menuParent) {
                return (
                  <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={(resource.options && resource.options.label) || resource.name}
                    leftIcon={resource.icon ? <resource.icon /> : null}
                    sidebarIsOpen={sidebarOpen[0]}
                    sx={{
                      color: grey[600],
                      padding: '6px 16px',
                      margin: sidebarOpen[0] ? '0 12px' : '3px',
                      fontWeight: '600',
                      borderRadius: '12px',
                      fontSize: '.9rem',
                      lineHeight: '1.75',
                      letterSpacing: '0.01em',
                      '&.RaMenuItemLink-active': {
                        backgroundColor: grey[200],
                        color: COLORS.light.primary.main,
                        '& .MuiListItemIcon-root': {
                          color: COLORS.light.primary.main
                        }
                      }
                    }}
                  />
                );
              }
            }

          })
        }
      </Box>
    </Menu>
  );
};

const SectionParent = ({ resource, resources }) => {
  const [open, setOpen] = useState(true);
  const sidebarOpen = useSidebarState();


  return (
    <Box style={{
      marginBottom: open && '6px',
    }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0px 12px',
          padding: '6px 9px 6px 15px',
          cursor: 'pointer',
          borderRadius: '12px',
          '&:hover': {
            backgroundColor: grey[100]
          }
        }}
        onClick={() => setOpen(!open)}
      >
        {
          sidebarOpen[0] && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <resource.icon style={{
                  color: grey[600],
                  marginLeft: '3px',
                  marginRight: '25px',
                  letterSpacing: '0.05em',
                  fontWeight: '600',
                  fontSize: '0.8rem'
                }} />
                <Typography
                  variant='subtitle2'
                  style={{
                    color: grey[600],
                    fontWeight: '600',
                    borderRadius: '12px',
                    fontSize: '.9rem',
                    lineHeight: '1.75',
                    letterSpacing: '0.01em',
                  }}
                >
                  {resource.name}
                </Typography>
              </Box>
              {
                open ? <MdExpandLess style={{ color: grey[600] }} /> : <MdExpandMore style={{ color: grey[600] }} />
              }
            </>
          )
        }
      </Box>
      <Collapse in={!sidebarOpen[0] || open} unmountOnExit >
        {
          resources.map((item) => {
            if (item.options?.menuParent && item.options?.menuParent === resource.name) {
              return (
                <MenuItemLink
                  key={item.name}
                  to={`/${item.name}`}
                  primaryText={(item.options && item.options.label) || item.name}
                  leftIcon={item.icon ? <item.icon /> : null}
                  sidebarIsOpen={open}
                  sx={{
                    margin: sidebarOpen[0] ? '0px 12px 0 24px' : '0px 3px',
                    color: grey[600],
                    padding: '6px 16px',
                    fontWeight: '600',
                    borderRadius: '12px',
                    fontSize: '0.85rem',
                    lineHeight: '1.75',
                    letterSpacing: '0.01em',
                    '&.RaMenuItemLink-active': {
                      backgroundColor: grey[200],
                      color: COLORS.light.primary.main,
                      '& .MuiListItemIcon-root': {
                        color: COLORS.light.primary.main
                      }
                    }
                  }}
                />
              );
            }
          })
        }
      </Collapse>
    </Box>
  );
};

export default MenuCustom;