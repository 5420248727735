import React from 'react';
import AdaptableForm from '../../../Components/AdaptableForm/AdaptableForm';
import InputText from '../../../Components/InputsForm/InputText';
import InputRichText from '../../../Components/InputsForm/InputRichText';
import { AutocompleteInput, ReferenceInput } from 'react-admin';
import InputAutocomplete from '../../../Components/InputsForm/InputAutocomplete';
import InputBoolean from '../../../Components/InputsForm/InputBoolean';

const FormFaqs = ({ isEdit }) => {

  const data = [
    {
      title: 'Information',
      inputs: [
        {
          field: <ReferenceInput source="category" reference='faqs/categories'>
            <InputAutocomplete fullWidth optionText='text' optionValue={'text'} />
          </ReferenceInput>,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputText fullWidth source="question" />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputRichText fullWidth source="answer" />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputBoolean fullWidth source="_archived" />,
          breakpoints: { xs: 8 }
        },
      ]
    }
  ];

  return (
    <AdaptableForm
      isEdit={isEdit}
      data={data}
    />
  );
};

export default FormFaqs;