import React from 'react';
import { Edit, useRecordContext } from 'react-admin';

const EditFormCustom = ({ children, transform, ...rest }) => {

  const onError = (e) => {
    console.log('EditFormCustom', e);
  };

  return (
    <Edit transform={transform ? transform : null} title={<TitleEdit />} redirect='list' mutationMode={'pessimistic'} {...rest}>
      {children}
    </Edit>
  );
};

const TitleEdit = () => {
  const record = useRecordContext();
  const title = record?.title?.length > 0 ? record.title : record?.name?.length > 0 ? record.name : '';
  return <span>{`Edit "${title}"`}</span>;
};

export default EditFormCustom;