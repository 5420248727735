
export const customValidationStartDate = (e, date) => {
  const firstDate = new Date(2023, 6, 6, 5);
  const lastDate = new Date(2023, 6, 11);
  if(!e) {
    return undefined;
  }
  if(new Date(e) < new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate())){
    return 'The selected Date cannot be before 06/07/2023';
  }
  if(new Date(e) > lastDate){
    return 'The selected Date cannot be past 09/07/2023';
  }
  return undefined;
};