import React, { useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useGetRecordId, useInput, useResourceContext } from 'react-admin';
import useUploadAws from '../../Hooks/useUploadAws';

const InputButtonAws = ({ source = 'picture' }) => {
  const { id, field } = useInput({ source });
  const [open, setOpen] = useState(false);
  const imageFieldSource = field?.value?.length > 0 ? { key: field.name, location: field.value } : null;
  const [image, setImage] = useState(imageFieldSource);
  const uploadAws = useUploadAws();

  const handleClick = async (e) => {
    const fileUpload = await uploadAws(e);
    if (fileUpload.success) {
      setImage(fileUpload);
      field.onChange(fileUpload.location);
      
    }
  };

  const handleDelete = () => {
    setImage(null);
    field.onChange('');
    setOpen(false);
  };

  const ModalDelete = () => (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        backgroundColor: '#fff', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        flexDirection: 'column', 
        transform: 'translate(-50%, -50%)', 
        width: '500px', 
        height: '300px'}}>
        <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center', gap: '20px'}} >
          <Typography variant='h5'>Delete image</Typography>
          <Typography variant='h7'>Are you sure you want to delete this image?</Typography>
        </Box>
        <Button style={{marginTop: '50px'}} variant="outlined" onClick={handleDelete}>Confirm</Button>
      </Box>
    </Modal>
  );
  return (
    <Box sx={{ display: 'flex', alignItems: 'start', gap: 2, marginBottom: 3 }}>
      <Box>
        <Button variant='outlined' component="label">
          Upload Image
          <input hidden accept="image/*" type="file" onChange={handleClick} />
        </Button>
      </Box>
      {
        image && (
          <img
            {...field}
            src={image?.location}
            alt={image?.key}
            loading="lazy"
            style={{ width: '60%', height: '60%', objectFit: 'contain', }}
          />
        )
      }
      {
        image && (
          <>
            <Button variant="text" style={{color: 'red'}} onClick={() => setOpen(true)} component="label" >
          Delete
            </Button>
          </>
        )
      }
      <ModalDelete />
    </Box>
  );
};

export default InputButtonAws;