import CreateSponsor from './Form/CreateSponsor';
import EditSponsor from './Form/EditSponsor';
import SponsorsList from './SponsorsList';
import { SiGithubsponsors } from 'react-icons/si';

export default {
  list: SponsorsList,
  create: CreateSponsor,
  edit: EditSponsor,
  icon: SiGithubsponsors
};