
import moment from 'moment';

export const convertTime = (utcTime) => {
  const costaRicaDate = moment.utc(utcTime).add(1, 'hours').format('YYYY-MM-DDTHH:mm');
  return costaRicaDate;
};

export const convertToUtc = (costaRicaTime) => {
  const costaRicaDate = moment.utc(costaRicaTime).subtract(1, 'hours').format('YYYY-MM-DDTHH:mm');
  return costaRicaDate;
};

export const convertEndDateToDuration = (startDate, endDate) => {
  let x = new moment(startDate);
  let y = new moment(endDate);
  let duration = moment.duration(x.diff(y)).as('minutes');
  let hours = Math.floor(duration / 60);
  let minutes = Math.ceil(duration % 60);
  return [hours, minutes];
};
