import React from 'react';
import { SelectInput, useChoicesContext } from 'react-admin';
import { INPUTS_VARIANT } from '../../assets/constants/Form';

const InputSelect = ({ optionText = 'text', optionValue = 'text', ...rest }) => {
  return (
    <SelectInput variant={INPUTS_VARIANT} optionText={optionText} optionValue={optionValue} {...rest} />
  );
};

export default InputSelect;