import { Box, Button } from '@mui/material';
import React from 'react';
import { Datagrid, List, SimpleList, Toolbar, useListContext } from 'react-admin';
import { COLORS } from '../../assets/constants/theme';

const ListCustom = ({ children, listParams, datagridParams }) => {
  return (
    <List
      sx={{}}
      {...listParams}
      // pagination={<PaginationCustom />}
      perPage={25}
      component={ListComponent}
    >
      <DatagridCustom datagridParams={datagridParams}>
        {children}
      </DatagridCustom>
    </List>
  );
};

const ListComponent = ({ children }) => {
  return (
    <Box style={{}}>
      {children}
    </Box>
  );
};

const DatagridCustom = ({ datagridParams, children }) => {
  return (
    <Datagrid
      size="medium"
      sx={{
        '& .RaDatagrid-headerCell': {
          backgroundColor: '#f2f2f2',
          color: COLORS.light.primary.main
        }
      }}
      {...datagridParams}
    >
      {children}
    </Datagrid>
  );
};

const PaginationCustom = () => {
  const { page, hasPreviousPage, hasNextPage, setPage, ...rest } = useListContext();
  if (!hasPreviousPage && !hasNextPage) return null;
  return (
    <Toolbar>
      {hasPreviousPage &&
        <Button
          key="previous"
          onClick={() => setPage(page - 1)}
        >
          Previous
        </Button>
      }
      {hasNextPage &&
        <Button
          key="next"
          onClick={() => setPage(page + 1)}
        >
          Next
        </Button>
      }
    </Toolbar>
  );
};

export default ListCustom;