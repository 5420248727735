import React, { useState } from 'react';
import { ReferenceArrayInput, ReferenceInput, required, useDataProvider, useRecordContext } from 'react-admin';
import AdaptableForm from '../../../Components/AdaptableForm/AdaptableForm';
import InputText from '../../../Components/InputsForm/InputText';
import InputButtonAws from '../../../Components/InputsForm/InputButtonAws';
import InputBoolean from '../../../Components/InputsForm/InputBoolean';
import InputAutocomplete from '../../../Components/InputsForm/InputAutocomplete';
import { Button, CircularProgress, TextField, Tooltip } from '@mui/material';
import ControllerComponent from '../../../Components/ControllerComponent/ControllerComponent';
import { MdHelpOutline } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import InputMainCategory from '../../../Components/InputsForm/InputCategories';

const FormTalents = ({ isEdit }) => {
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const dataProvider = useDataProvider();
  const record = useRecordContext();

  const handleAutogenerateButton = (replace) => {
    setLoadingGenerate(true);
    const res = dataProvider.getCustom(`talents/${record.id}/generate_bio`);
    res.then(({ json }) => {
      setLoadingGenerate(false);
      replace(json.result);
    });
  };
  
  const data = [
    {
      title: 'Artist Details',
      inputs: [
        {
          field: <InputText
            source='name'
            label='Artist Name'
            fullWidth
            validate={[required()]}
          />,
          breakpoints: { xs: 4 }
        },
        {
          field: <InputText
            source='email'
            fullWidth
          />,
          breakpoints: { xs: 4 }
        },
        {
          field: <InputText
            source='phone'
            fullWidth
          />,
          breakpoints: { xs: 4 }
        },
        {
          field: <InputText
            source='contact_name'
            fullWidth
          />,
          breakpoints: { xs: 4 }
        },
        {
          field: <InputText
            source='country'
            fullWidth
          />,
          breakpoints: { xs: 4 }
        },
        {
          field: <InputText
            source='slogan'
            fullWidth
          />,
          breakpoints: { xs: 4 }
        },
        {
          field: <ReferenceInput source='main_stage' reference={'venues'} fullWidth>
            <InputAutocomplete optionText="name" optionValue='id' />
          </ReferenceInput>,
          breakpoints: { xs: 4 }
        },
        {
          field: <ReferenceArrayInput source='pillars' reference={'talents/pillars'} fullWidth>
            <InputAutocomplete isArray optionText="text" optionValue='text' />
          </ReferenceArrayInput>,
          breakpoints: { xs: 4 }
        },
        {
          field: <InputMainCategory source="main_category" />,
          breakpoints: { xs: 4 }
        },
        // {
        //   field: <ReferenceArrayInput source='main_category' reference={'talents/categories'} fullWidth>
        //     <InputAutocomplete optionText="text" optionValue='text' />
        //   </ReferenceArrayInput>,
        //   breakpoints: { xs: 4 }
        // },
        {
          field: <InputText
            source='message'
            fullWidth
          />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputText
            source='approved_tracks'
            fullWidth
          />,
          breakpoints: { xs: 8 }
        },
        // {
        //   field: <InputBoolean label="Online Content" source="online_content" />,
        //   breakpoints: { xs: 8 }
        // },
      ]
    },
    {
      title: 'Basic Info',
      inputs: [
        {
          field: <InputText
            source='short_bio'
            fullWidth
          />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputText
            source='long_bio'
            multiline
            fullWidth
          />,
          breakpoints: { xs: 12 }
        },
        isEdit && {
          field: <ControllerComponent name={'long_bio'}>
            {
              (replace) =>
                <Tooltip placement="right" title="For a better result, complete and save the following fields: Email, Country, Pillars and Message" variant="soft">
                  <Button
                    onClick={() => handleAutogenerateButton(replace)}
                    endIcon={loadingGenerate && <CircularProgress style={{ marginLeft: '9px', width: '18px', height: '18px' }} />}>
                    Autogenerate Long Bio with AI
                  </Button>
                </Tooltip>
            }
          </ControllerComponent>,
          breakpoints: { xs: 8 }
        }
      ]
    },
    {
      title: 'Media',
      inputs: [
        {
          field: <InputText
            source='media.instagram.url'
            label='Instagram url'
            fullWidth
          />,
          breakpoints: { xs: 4 }
        },
        // {
        //   field: <InputText
        //     source='media.instagram.embed'
        //     label='Instagram embed'
        //     fullWidth
        //   />,
        //   breakpoints: { xs: 4 }
        // },
        {
          field: <InputText
            source='media.instagram.description'
            label='Instagram description'
            fullWidth
          />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputText
            source='media.soundcloud.url'
            label='Soundcloud url'
            fullWidth
          />,
          breakpoints: { xs: 4 }
        },
        // {
        //   field: <InputText
        //     source='media.soundcloud.embed'
        //     label='Soundcloud embed'
        //     fullWidth
        //   />,
        //   breakpoints: { xs: 4 }
        // },
        {
          field: <InputText
            source='media.soundcloud.description'
            label='Soundcloud description'
            fullWidth
          />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputText
            source='media.spotify.url'
            label='Spotify url'
            fullWidth
          />,
          breakpoints: { xs: 4 }
        },
        // {
        //   field: <InputText
        //     source='media.spotify.embed'
        //     label='Spotify embed'
        //     fullWidth
        //   />,
        //   breakpoints: { xs: 4 }
        // },
        {
          field: <InputText
            source='media.spotify.description'
            label='Spotify description'
            fullWidth
          />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputText
            source='media.youtube.url'
            label='Youtube url'
            fullWidth
          />,
          breakpoints: { xs: 4 }
        },
        // {
        //   field: <InputText
        //     source='media.youtube.embed'
        //     label='Youtube embed'
        //     fullWidth
        //   />,
        //   breakpoints: { xs: 4 }
        // },
        {
          field: <InputText
            source='media.youtube.description'
            label='Youtube description'
            fullWidth
          />,
          breakpoints: { xs: 8 }
        },
      ]
    },
    {
      title: 'Picture',
      inputs: [
        {
          field: <InputButtonAws source={'picture.original'} />,
          breakpoints: { xs: 8 }
        },
      ]
    },
  ];

  return (
    <AdaptableForm
      isEdit={isEdit}
      data={data}
    />
  );
};

export default FormTalents;