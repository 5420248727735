import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MdClose, MdSearch } from 'react-icons/md';
import { defaultKeys } from '../../assets/constants/Calendar';
import useDebounce from '../../Hooks/useDebounce';
import { filterSanitizer } from '../../helpers/multiuse';

const ModalAddSecondaryItems = ({ items, canvasClicked, setCanvasClicked, handleAddPrevItem, handleDeletePrevItem, saveItem }) => {
  const [itemClicked, setItemClicked] = useState(null);
  const [itemsList, setItemsList] = useState(items);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 600);

  useEffect(() => {
    if (search == '') {
      setItemsList(items);
    } else {
      const filterListItemSearch = itemsList.filter((item) => filterSanitizer(item, search, 'name'));
      setItemsList(filterListItemSearch);
    }
  }, [debouncedSearch]);

  const handleClose = () => {
    setCanvasClicked(null);
    itemClicked && handleDeletePrevItem(itemClicked);
  };

  const handleSaveButton = () => {
    const saved = saveItem(itemClicked.id, canvasClicked.time, canvasClicked.groupId);
    !saved && handleDeletePrevItem(itemClicked);
    setCanvasClicked(null);
  };

  const handleClickItem = (item) => {
    if (itemClicked) {
      handleDeletePrevItem(itemClicked);
    }
    const itemPrevAdd = {
      ...item,
      [defaultKeys['itemTimeStartKey']]: moment(canvasClicked.time),
      [defaultKeys['itemTimeEndKey']]: moment(canvasClicked.time).add(15, 'minutes'),
      [defaultKeys['itemGroupKey']]: canvasClicked.groupId
    };

    setItemClicked(itemPrevAdd);
    handleAddPrevItem(itemPrevAdd);
  };

  return (
    <Box sx={{ position: 'absolute', right: 0, top: 0, height: '100%', width: 420, zIndex: 99 }}>
      <Box sx={{
        position: 'sticky',
        top: 200,
        right: 0,
        width: 400,
        backgroundColor: '#fff',
        borderRadius: 3,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
        padding: 2,
        overflow: 'hidden',
      }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
          <TextField
            variant='outlined'
            size='small'
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: <MdSearch color='grey' size={21} />
            }}
          />
          <Box>
            <IconButton aria-label="close">
              <MdClose size={27} color='grey' onClick={handleClose} />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{
          height: '60vh',
          paddingBottom: 3,
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2, marginTop: 4 }}>
            {
              itemsList.map((item) => (
                <Box
                  onClick={() => handleClickItem(item)}
                  sx={{
                    boxShadow: itemClicked?.id == item.id ? 'black 0px 0px 3px 2px inset' : '',
                    overflow: 'hidden', cursor: 'pointer', width: '172px', height: '90px',
                    borderRadius: '6px', padding: 1, backgroundColor: 'rgb(33, 150, 243)'
                  }}
                >
                  <Typography color='#fff' variant='body1'>{item.name}</Typography>
                </Box>
              ))
            }
          </Box>
        </Box>
        <Box sx={{ paddingTop: '9px' }}>
          <Button disabled={!itemClicked} fullWidth variant='contained' color='secondary' onClick={handleSaveButton}>Save</Button>
        </Box>
      </Box >
    </Box >
  );
};

// const ItemSecondaryModal = () => {


//   return (
//     <Box onClick={} sx={{ overflow: 'hidden', cursor: 'pointer', width: '172px', height: '90px', borderRadius: '6px', padding: 1, backgroundColor: 'rgb(33, 150, 243)' }}>
//       <Typography color='#fff' variant='body1'>{item.name}</Typography>
//     </Box>
//   )
// }

export default ModalAddSecondaryItems;