import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import { SimpleForm, useEditContext } from 'react-admin';

const AdaptableForm = ({ simpleform, data }) => {
  const { isLoading } = useEditContext();

  if (isLoading) {
    return (
      <Box sx={{ width: '100%', padding: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <SimpleForm {...simpleform} sanitizeEmptyValues>
      <Box sx={{ maxWidth: 1400, width: '100%' }}>
        {
          data?.map((item, index) => (
            <Grid
              container
              sx={{ borderBottom: index !== data.length - 1 && '1px solid rgba(0,0,0, .15)', marginY: 2 }}
              key={`AdaptableFormKey-${index}`}
            >
              <Grid item xs={2}>
                <Typography sx={{ marginTop: 1 }} variant='subtitle2'>
                  {item.title.toUpperCase()}
                </Typography>
              </Grid>
              <Grid container columnSpacing={2} xs={10}>
                {
                  item.inputs.map((input, key) => (
                    <Grid
                      display={input.hide ? 'none' : 'block'}
                      key={key}
                      item
                      {...input?.breakpoints}
                    >
                      {input?.field}
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          ))
        }
      </Box>
    </SimpleForm>
  );
};

export default AdaptableForm;