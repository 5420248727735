import { Box } from '@mui/material';
import { CreateButton } from 'react-admin';

const ListActions = ({ children, ...rest }) => {

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: 3,
      padding: 1,
      backgroundColor: '#fff',
      zIndex: 999,
      position: 'sticky',
      top: 0,
      paddingBottom: '12px'
    }}>
      {children}
      {
        rest.hasCreate && (
          <CreateButton variant='contained' size='small' sx={{ alignItems: 'center' }} />
        )
      }
    </Box>
  );
};

export default ListActions;