import { Autocomplete, Box, Button, Chip, FormControl, FormControlLabel, FormGroup, MenuItem, Paper, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';
import { apiUrl } from '../../dataProvider';

const FilterButtons = ({setFilter, groups}) => {
  const [tags, setTags] = useState([]);
  const dataProvider = useDataProvider();
  const CustomPaper = (props) => {
    return <Paper elevation={98123} {...props} />;
  };

  const params = {
    limit: 1000,
    offset: 0,
    sort_by: 'type',
    ascending: false,
    filter: {}
  };
  
  useEffect(() => {
    refetch();
  },[]);

  const { data, refetch } = useQuery(
    ['tags'], () => axios({
      method: 'GET',
      url: `${apiUrl}/events/tags?${JSON.stringify(params)}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        'Authorization': localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY
      }
    }),
    {
      onSuccess: ({data}) => setTags(data.results),
      enabled: true
    }
  );

  return(
    <Box style={{widht: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', alignContent: 'flex-end'}} >
      <Autocomplete 
        disablePortal
        multiple
        style={{width: '250px'}}
        options={groups}
        variant="outlined"
        label="Venues"
        Drop
        getOptionLabel={(options) => options.name}
        onChange={(e,v) => setFilter((f) => ({...f, venues: v?.map(item => item.id)}))}
        PaperComponent={CustomPaper}
        optionText="name"
        renderInput={(params) => <TextField {...params} variant="outlined" label="Venues" />}
        renderTags={(params) =>
          params.map(e => <Typography variant="outlined" style={{ fontSize: '0.8em', borderRadius: '5px', fontWeight: 400, padding: '3px 8px', margin: '0 3px', backgroundColor: '#e2f1f8' }}>{e.name}</Typography>)}
      />
      <Autocomplete 
        disablePortal
        multiple
        style={{width: '250px'}}
        options={tags}
        variant="outlined"
        label="Venues"
        Drop
        getOptionLabel={(options) => options}
        onChange={(e,v) => setFilter((f) => ({...f, tags: v}))}
        PaperComponent={CustomPaper}
        optionText="name"
        renderInput={(params) => <TextField {...params} variant="outlined" label="Event / Tags" />}
        renderTags={(params) =>
          params.map(e => <Typography variant="outlined" style={{ fontSize: '0.8em', borderRadius: '5px', fontWeight: 400, padding: '3px 8px', margin: '0 3px', backgroundColor: '#e2f1f8' }}>{e}</Typography>)}
      />
    </Box>
  );
};

export default FilterButtons;