import React from 'react';
import FormBlogs from './FormBlogs';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';

const EditBlogs = () => {
  return (
    <EditFormCustom>
      <FormBlogs isEdit />
    </EditFormCustom>
  );
};

export default EditBlogs;