import React from 'react';
import FormEvents from './FormEvents';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';
import { transform } from './Transform';

const EditEvents = () => {
  return (
    <EditFormCustom transform={transform}>
      <FormEvents isEdit />
    </EditFormCustom>
  );
};

export default EditEvents;