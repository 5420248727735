import React from 'react';
import { DateInput, DateTimeInput } from 'react-admin';
import { INPUTS_VARIANT } from '../../assets/constants/Form';

const InputDate = ({ inputTime, ...rest }) => {

  if (inputTime) {
    return (
      <DateTimeInput variant={INPUTS_VARIANT} {...rest} />
    );
  }
  else {
    return (
      <DateInput variant={INPUTS_VARIANT} {...rest} />
    );
  }
};

export default InputDate;