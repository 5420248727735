import React from 'react';
import { ImageField, ReferenceField, TextField } from 'react-admin';
import ListCustom from '../../Components/ListCustom/ListCustom';
import ListActions from '../../Components/ListActions/ListActions';

const paramsList = {
  listParams: {
    hasCreate: true,
    actions: <ListActions />
  },
  datagridParams: {
    bulkActionButtons: false,
    rowClick: 'edit'
  }
};

const TalentsList = () => {
  return (
    <ListCustom {...paramsList}>
      <ImageField
        source="picture.original"
        label=''
        sx={{ '& img': { borderRadius: 200, maxWidth: 39, maxHeight: 39, objectFit: 'cover' } }}
      />
      <TextField source="name" />
      <TextField source="country" />
      <TextField source="email" />
      <TextField source="main_category" />
      <ReferenceField source="main_stage" reference="venues">
        <TextField source="name" />
      </ReferenceField>
    </ListCustom>
  );
};

export default TalentsList;