import React from 'react';
import FormAdmins from './FormAdmins';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';
import { transform } from './transform';

const EditAdmins = () => {
  return (
    <EditFormCustom>
      <FormAdmins isEdit />
    </EditFormCustom>
  );
};

export default EditAdmins;