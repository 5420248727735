import BlogList from './BlogList';
import CreateBlogs from './Form/CreateBlogs';
import EditBlogs from './Form/EditBlogs';
import { BsLayoutTextSidebarReverse } from 'react-icons/bs';

export default {
  list: BlogList,
  create: CreateBlogs,
  edit: EditBlogs,
  icon: BsLayoutTextSidebarReverse,
};