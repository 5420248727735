import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { stage_color } from '../../assets/constants/Calendar';

const GroupItemCalendar = ({ group, selected, handleClick }) => {
  // const [selectedGroup, setSelectedGroup] = useState(false);

  // const handleClickGroup = () => {
  //   console.log('handleClickGroup', group, selectedGroup);
  //   if (selectedGroup) {
  //     setGroupList(allGroups);
  //   } else {
  //     setGroupList([group]);
  //   }
  //   setSelectedGroup(prev => !prev);
  // };

  // useEffect(() => {
  //   console.log('selectedGroup', selectedGroup);
  // }, [selectedGroup]);

  return (
    <Box
      onClick={() => handleClick(group)}
      sx={{
        cursor: 'pointer',
        border: selected && '1px solid rgba(0,0,0, .2)',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: 1,
        borderRadius: 2
      }}
    >
      <Box
        sx={{
          minWidth: '15px',
          height: '15px',
          borderRadius: 5,
          backgroundColor: stage_color[group.id]?.light ? stage_color[group.id]?.light : 'none'
        }}
      />
      <Typography
        variant='subtitle2'
        style={{
          whiteSpace: 'normal',
          overflowWrap: 'break-word',
          marginLeft: '6px'
        }}
      >
        {group.name}
      </Typography>
    </Box>
  );
};

export default GroupItemCalendar;