import React from 'react';
import FormVenues from './FormVenues';
import { Create } from 'react-admin';

const CreateVenues = () => {
  return (
    <Create redirect='list' mutationMode={'pessimistic'}>
      <FormVenues />
    </Create>
  );
};

export default CreateVenues;