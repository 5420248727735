import React from 'react';
import { TextInput } from 'react-admin';
import { INPUTS_VARIANT } from '../../assets/constants/Form';

const InputText = ({ ...rest }) => {
  return (
    <TextInput
      {...rest}
      size="small"
      variant={INPUTS_VARIANT}
    />
  );
};

export default InputText;