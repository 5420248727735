import React from 'react';
import FormVenues from './FormVenues';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';

const EditVenues = () => {
  return (
    <EditFormCustom>
      <FormVenues isEdit />
    </EditFormCustom>
  );
};

export default EditVenues;