import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import ModalItemClicked from './ModalItemClicked';

const CalendarItem = ({ item, itemContext, getItemProps, getResizeProps, keys, timelineContext }) => {
  const [modalOpen, setModalOpen] = useState(itemContext.selected);
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const [formatDate, setFormatDate] = useState({
    start_date: moment(item[keys.itemTimeStartKey]).format('HH:mm'),
    end_date: moment(item[keys.itemTimeEndKey]).format('HH:mm')
  });
  const itemProps = getItemProps();
  const { border, ...styles } = itemProps.style;

  const defaultsDates = {
    start_date: moment(item[keys.itemTimeStartKey]).format('HH:mm'),
    end_date: moment(item[keys.itemTimeEndKey]).format('HH:mm')
  };

  const handleClose = () => {
    itemContext.selected = false;
  };

  useEffect(() => {
    if (itemContext.dragging) {
      const endTimeDrag = itemContext.dragTime + (item[keys.itemTimeEndKey] - item[keys.itemTimeStartKey]);
      const format_date = {
        start_date: moment(itemContext.dragTime).format('HH:mm'),
        end_date: moment(endTimeDrag).format('HH:mm')
      };
      setFormatDate(format_date);
    } else if (itemContext.resizing) {
      setFormatDate(prev => ({ ...prev, end_date: moment(itemContext.resizeTime).format('HH:mm') }));
    } else if (itemContext.selected) {
      setFormatDate(defaultsDates);
    }
  }, [itemContext]);

  useEffect(() => {
    setModalOpen(itemContext.selected);
  }, [itemContext.selected]);

  return (
    <Box sx={{ ...styles, borderRadius: '6px', }}>
      <div
        {...itemProps}
        style={{ backgroundColor: 'rgb(33, 150, 243)', overflow: 'hidden', borderRadius: '6px', border: 'none', boxShadow: modalOpen ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : 'none' }}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <Box
          sx={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'rgb(33, 150, 243)',
            border: 'none',
            padding: 1
          }}
        >
          <Typography variant="subtitle1" sx={{ lineHeight: '21px' }}>{itemContext.title}</Typography>
          {
            item?.talents?.length > 0 && (
              <Typography variant="subtitle2">{item?.talents[0]?.name}</Typography>
            )
          }
          <Typography variant="body2">{`${formatDate?.start_date} - ${formatDate?.end_date}`}</Typography>
        </Box>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}


      </div>
      {
        (modalOpen && !itemContext.dragging && !itemContext.resizing) && (
          <ModalItemClicked itemClicked={item} handleClose={handleClose} timelineContext={timelineContext} />
        )
      }
    </Box>
  );
};

export default CalendarItem;