import dataProvider from './dataProvider';
import { Admin, Resource } from 'react-admin';
import Events from './Resources/Events';
import Talents from './Resources/Talents';
import { QueryClient } from 'react-query';
import { lightTheme } from './Theme';
import LayoutCustom from './Components/LayoutCustom/LayoutCustom';
import LoginPageCustom from './Resources/Login/LoginPageCustom';
import authProvider from './authProvider';
import Venues from './Resources/Venues';
import Notifications from './Resources/Notifications';
import Faqs from './Resources/Faqs';
import Blogs from './Resources/Blogs';
import Heros from './Resources/Heros';
import Admins from './Resources/Admins';

import { FaBullhorn } from 'react-icons/fa';
import { RiAdminLine } from 'react-icons/ri';
import { TfiMoreAlt } from 'react-icons/tfi';
import { userAccessControl } from './helpers/permissions';
import { useEffect } from 'react';
import moment from 'moment-timezone';
import './App.css';
import Users from './Resources/Users';
import Sponsors from './Resources/Sponsors';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    },
  },
});

function App() {

  useEffect(() => {
    moment.tz.setDefault('Europe/London');
  }, []);

  return (
    <Admin 
      queryClient={queryClient}
      dataProvider={dataProvider}
      theme={lightTheme}
      layout={LayoutCustom}
      authProvider={authProvider}
      loginPage={LoginPageCustom} 
      requireAuth
      disableTelemetry
    >
      {
        permissions => [
          userAccessControl(permissions, 'read', ['talents']) ?
            <Resource name="talents" options={{ label: 'Talent'}} {...Talents} /> : null,
          userAccessControl(permissions, 'read', ['events']) ?    
            <Resource name="events" options={{ label: 'Events'}} {...Events} /> : null,
          userAccessControl(permissions, 'read', ['venues']) ?
            <Resource name="venues" options={{ label: 'Venues'}} {...Venues} /> : null,
    
          userAccessControl(permissions, 'read', ['admins']) ?
            <Resource name='Admin' options={{ isMenuParent: true }} icon={RiAdminLine} /> : null,
          userAccessControl(permissions, 'read', ['admins']) ?
            <Resource name="admins" options={{ label: 'Users', 'menuParent': 'Admin' }} {...Admins} /> : null,

          userAccessControl(permissions, 'read', ['heros', 'notifications', 'posts', 'faqs']) ?
            <Resource name='Marketing' options={{ isMenuParent: true }} icon={FaBullhorn} /> : null,
          userAccessControl(permissions, 'read', ['heros']) ?
            <Resource name="heroes" options={{ label: 'Hero Images', 'menuParent': 'Marketing' }} {...Heros} />: null,
          userAccessControl(permissions, 'read', ['notifications']) ?
            <Resource name="notifications" options={{ label: 'Notifications', 'menuParent': 'Marketing' }} {...Notifications} /> : null,
          userAccessControl(permissions, 'read', ['posts']) ?
            <Resource name="posts" options={{ label: 'Posts', 'menuParent': 'Marketing' }} {...Blogs} /> : null,
          userAccessControl(permissions, 'read', ['faqs']) ?
            <Resource name="faqs" options={{ label: 'Faqs', 'menuParent': 'Marketing' }} {...Faqs} /> : null,
          userAccessControl(permissions, 'read', ['users']) ?
            <Resource name="users" options={{ label: 'Users' }} {...Users} />: null,
        ]

        /* <Resource name='More' options={{ isMenuParent: true }} icon={TfiMoreAlt} /> */
        
      }
    </Admin>
  );
}

export default App;
