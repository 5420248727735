import React from 'react';
import ListCustom from '../../Components/ListCustom/ListCustom';
import { TextField } from 'react-admin';
import ListActions from '../../Components/ListActions/ListActions';

const AdminsList = () => {

  const paramsList = {
    listParams: {
      hasCreate: true,
      actions: <ListActions />
    },
    datagridParams: {
      bulkActionButtons: false,
      rowClick: 'edit'
    }
  };

  return (
    <ListCustom {...paramsList}>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="role" />
    </ListCustom>
  );
};

export default AdminsList;