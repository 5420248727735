
export const ROLES = [
  {
    id: 1,
    text: 'admin'
  },
  {
    id: 2,
    text: 'staff'
  }, 
];

//resources = ['talents', 'events', 'venues', 'admins', 'heros', 'notifications', 'posts', 'faqs']

export const PERMISSIONS = {
  talents: { //resource
    create: ['superadmin', 'admin', 'staff'], //role
    update: ['superadmin', 'admin', 'staff', 'talent'],
    delete: ['superadmin', 'admin','staff'],
    read: ['superadmin', 'admin','staff', 'talent']
  },
  events: {
    create: ['superadmin', 'admin', 'staff'],
    update: ['superadmin', 'admin', 'staff', 'talent'],
    delete: ['superadmin', 'admin','staff'],
    read: ['superadmin', 'admin','staff', 'talent']
  },
  venues: {
    create: ['superadmin', 'admin', 'staff'],
    update: ['superadmin', 'admin', 'staff'],
    delete: ['superadmin', 'admin','staff'],
    read: ['superadmin', 'admin','staff']
  },
  admins: {
    create: ['superadmin', 'admin'],
    update: ['superadmin', 'admin'],
    delete: ['superadmin', 'admin'],
    read: ['superadmin', 'admin']
  },
  heros: {
    create: ['superadmin', 'admin', 'staff'],
    update: ['superadmin', 'admin', 'staff'],
    delete: ['superadmin', 'admin','staff'],
    read: ['superadmin', 'admin','staff']
  },
  notifications: {
    create: ['superadmin', 'admin', 'staff'],
    update: ['superadmin', 'admin', 'staff'],
    delete: ['superadmin', 'admin','staff'],
    read: ['superadmin', 'admin','staff']
  },
  posts: {
    create: ['superadmin', 'admin', 'staff'],
    update: ['superadmin', 'admin', 'staff'],
    delete: ['superadmin', 'admin','staff'],
    read: ['superadmin', 'admin','staff']
  },
  faqs: {
    create: ['superadmin', 'admin', 'staff'],
    update: ['superadmin', 'admin', 'staff'],
    delete: ['superadmin', 'admin','staff'],
    read: ['superadmin', 'admin','staff']
  },
  sponsors: {
    create: ['superadmin', 'admin'],
    update: ['superadmin', 'admin'],
    delete: ['superadmin', 'admin'],
    read: ['superadmin', 'admin']
  },
  users: {
    create: ['superadmin'], //role
    update: ['superadmin'],
    delete: ['superadmin'],
    read: ['superadmin']
  }
};