import React from 'react';
import { Layout } from 'react-admin';
import AppbarCustom from '../AppbarCustom/AppbarCustom';
import MenuCustom from './MenuCustom';
import ErrorCustom from '../ErrorCustom/ErrorCustom';

const LayoutCustom = (props) => {
  return (
    <Layout {...props} error={ErrorCustom} menu={MenuCustom} appBar={AppbarCustom} />
  );
};

export default LayoutCustom;