import React from 'react';
import { Create } from 'react-admin';
import FormFaqs from './FormFaqs';
import { transform } from './Transform';

const CreateFaqs = () => {
  return (
    <Create redirect='list' transform={transform} mutationMode={'pessimistic'}>
      <FormFaqs />
    </Create>
  );
};

export default CreateFaqs;