import React from 'react';
import AdaptableForm from '../../../Components/AdaptableForm/AdaptableForm';
import InputText from '../../../Components/InputsForm/InputText';
import InputSelect from '../../../Components/InputsForm/InputSelect';
import { TYPES_VENUES } from '../../../assets/constants/typesVenues';
import InputImage from '../../../Components/InputsForm/InputImage';
import InputButtonAws from '../../../Components/InputsForm/InputButtonAws';
import { ReferenceArrayInput, required } from 'react-admin';
import InputAutocomplete from '../../../Components/InputsForm/InputAutocomplete';

const FormVenues = ({ isEdit }) => {

  const data = [
    {
      title: 'Information',
      inputs: [
        {
          field: <InputText fullWidth source="name" validate={[required()]} />,
          breakpoints: { xs: 6 }
        },
        {
          field: <ReferenceArrayInput  source="type" reference='venues/types'>
            <InputAutocomplete optionValue="text" validate={[required()]} optionText='text' fullWidth />
          </ReferenceArrayInput>,
          breakpoints: { xs: 6 }
        },
        {
          field: <InputText fullWidth source="description" multiline validate={[required()]} />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputText fullWidth source="location" />,
          breakpoints: { xs: 6 }
        },
      ]
    },
    {
      title: 'Image',
      inputs: [
        {
          field: <InputButtonAws source={'picture.original'} />,
          breakpoints: { xs: 8 }
        },
      ]
    }
  ];

  return (
    <AdaptableForm
      isEdit={isEdit}
      data={data}
    />
  );
};

export default FormVenues;