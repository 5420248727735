import React from 'react';
import { Create } from 'react-admin';
import FormAdmins from './FormAdmins';
import { transform } from './transform';

const CreateAdmins = () => {
  return (
    <Create redirect='list' mutationMode={'pessimistic'}>
      <FormAdmins />
    </Create>
  );
};

export default CreateAdmins;