import React from 'react';
import { Create } from 'react-admin';
import FormTalents from './FormTalents';
import { transform } from './Transforms';

const CreateTalents = () => {
  return (
    <Create transform={transform} redirect='list' mutationMode={'pessimistic'}>
      <FormTalents />
    </Create>
  );
};

export default CreateTalents;