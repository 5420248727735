import React from 'react';
import ListCustom from '../../Components/ListCustom/ListCustom';
import { TextField } from 'react-admin';
import ListActions from '../../Components/ListActions/ListActions';

const paramsList = {
  listParams: {
    hasCreate: true,
    actions: <ListActions />
  },
  datagridParams: {
    bulkActionButtons: false,
    rowClick: 'edit'
  }
};

const BlogList = () => {
  return (
    <ListCustom {...paramsList}>
      <TextField source="title" />
      <TextField source="author" />
      <TextField source="category" />
    </ListCustom>
  );
};

export default BlogList;