import React from 'react';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';
import FormUsers from './FormUsers';

const EditUsers = () => {
  return (
    <EditFormCustom>
      <FormUsers isEdit />
    </EditFormCustom>
  );
};

export default EditUsers;