import React from 'react';
import { BooleanField, DateField, NumberField, TextField } from 'react-admin';
import ListCustom from '../../Components/ListCustom/ListCustom';
import ListActions from '../../Components/ListActions/ListActions';

const paramsList = {
  listParams: {
    hasCreate: true,
    actions: <ListActions />
  },
  datagridParams: {
    bulkActionButtons: false,
    rowClick: 'edit'
  }
};

const NotificationsList = () => {
  return (
    <ListCustom {...paramsList}>
      <TextField source="title" />
      <TextField source="body" />
      <TextField source="status" />
      <DateField source="send_at" showTime />
      <NumberField source="notifications_sent" label={'Sent'} />
    </ListCustom>
  );
};

export default NotificationsList;