import React from 'react';
import { Create } from 'react-admin';
import FormEvents from './FormEvents';
import { transform } from './Transform';

const CreateEvents = () => {
  return (
    <Create transform={transform} redirect='list' mutationMode={'pessimistic'}>
      <FormEvents />
    </Create>
  );
};

export default CreateEvents;