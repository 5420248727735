import { fetchUtils } from 'react-admin';
import { createIdsArray, getQueryParams } from '../helpers/api';
import axios from 'axios';

export const apiUrl = process.env.REACT_APP_BASE_PATH;

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('Access-Control-Allow-Origin', '*');
  options.headers.set('Access-Control-Expose-Headers', 'content-range');
  options.headers.set('Authorization', localStorage.getItem('auth')); 
  options.headers.set('x-api-key', process.env.REACT_APP_API_KEY); 
  return fetchUtils.fetchJson(url, options);
};

export default {
  getList: (resource, params) => {
    const query = {
      pagination: params.pagination,
      sort: params.sort,
      filter: params.filter
    };
    const queryParams = getQueryParams(query);

    const url = `${apiUrl}/${resource}/${queryParams}`;
    // const url = `${apiUrl}/${resource}/?limit=${perPage}&offset=${(page - 1) * perPage}`;
    
    return httpClient(url).then(({ headers, json }) => {
      let data = json?.results;
      if(resource.includes('/')){ //esto es porque las rutas que tienen / no devuelven bien formateado
        data = createIdsArray(data);
      }
      return({
        data: data,
        total: json?.paging?.total
      });
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${JSON.stringify(query)}`;
    return httpClient(url).then(({ json }) => {
      let data = json.results;
      if(resource.includes('/')){ //esto es porque las rutas que tienen / no devuelven bien formateado
        data = createIdsArray(data);
      }
      return({ 
        data: data
      });
    });
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${JSON.stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.results,
      total: json.paging.total
    }));
  },

  getCustom: (resource) => {
    const url = `${apiUrl}/${resource}`;
    return httpClient(url).then(r => r);
  },

  create: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    };
    return httpClient(`${apiUrl}/${resource}?${JSON.stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    };
    return httpClient(`${apiUrl}/${resource}?${JSON.stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};