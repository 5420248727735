export const MIN_MINUTES_EVENTS = 10;

export const styleTitleDate = {
  cursor: 'pointer',
  color: 'rgba(0,0,0,.8)',
  fontWeight: '600',
  lineHeight: '30px',
  position:'sticky'
  // borderLeft: '1px solid rgba(0,0,0,.3)'
};

export const TimeStepsCalendar = {
  minute: 15,
  hour: 1,
  day: 1,
  month: 1,
};

export const itemPropsDefaults = {
  canMove: true,
  canResize: true,
  canChangeGroup: true,
};

export const defaultKeys = {
  groupIdKey: 'id',
  groupTitleKey: 'name',
  itemIdKey: 'id',
  itemTitleKey: 'name',    // key for item div content
  itemDivTitleKey: 'name', // key for item div title (<div title="text"/>)
  itemGroupKey: 'stageFormat',
  itemTimeStartKey: 'start_date',
  itemTimeEndKey: 'end_date',
};

export const stage_color = {
  '646cc91fb39e23cf91dd3be1': { //Village
    solid: '#0B1A55',
    light: '#AFC9BE',
    medium: '#858CAA',
    typography: '#FFFFFF'
  },
  '646cc8f39cb3eef625255876' : { // El Circo
    solid: '#912579',
    light: '#D9B5D1',
    medium: '#80B3A3',
    typography: '#FFFFFF'
  },
  '646cc8e5b39e23cf91dd3be0': { // Templo del Cielo
    solid: '#00B8D9',
    light: '#ACECF7',
    medium: '#80BDD0',
    typography: '#FFFFFF'
  },
  '646cc8d3b39e23cf91dd3bdf' : { // Templo de las Estrellas
    solid: '#B18665',
    light: '#E5D6CB',
    medium: '#E5B292',
    typography: '#FFFFFF'
  },
  '646cc8c33f3bc68083b31032' : { //Village Witches
    solid: '#006BB5',
    light: '#A8CDE6',
    medium: '#FEDD9A',
    typography: '#FFFFFF'
  },
  '646cc8a89cb3eef625255875' : { //Red Tent
    solid: '#C80F0B',
    light: '#E99F9D',
    medium: '#FBF1E0',
    typography: '#FFFFFF'
  },
  '646cc89a9cb3eef625255874' : { //Sacred Fire
    solid: '#F47927',
    light: '#FBD1B5',
    medium: '#BBDEED',
    typography: '#FFFFFF'
  },
  '646cc88d9cb3eef625255873' : { //Earth Temple
    solid: '#2E7477',
    light: '#B8D0D1',
    medium: '#80D3C2',
    typography: '#FFFFFF'
  },
  '646cc87a9cb3eef625255872' : { //Sol
    solid: '#FFD70D',
    light: '#FFF2AD',
    medium: '#F0A7A0',
    typography: '#855A3A'
  },
  '63c5bb4ea79491efa54586b6' : { //Luna
    solid: '#6533B4',
    light: '#6533B4',
    medium: '#8A64C7',
    typography: '#FFFFFF'
  },
  '63c5bb4fa79491efa54586b8' : { //Lapa
    solid: '#FF1D1D',
    light: '#FFA4A4',
    medium: '#FF6161',
    typography: '#FFFFFF'
  },
  '63c5bb55a79491efa54586c8' : { //Starseed Family Village
    solid: '#1BCBA7',
    light: '#B1EDE1',
    medium: '#51D8BC',
    typography: '#FFFFFF'
  },
  '63c5bb54a79491efa54586c5' :{ //Art Gallery
    solid: '#FF2EA3',
    light: '#FFABDA',
    medium: '#FF6DBF',
    typography: '#FFFFFF'
  },
  '63e926ac48575f4eb89c8f27' :{ //Humans i trust
    solid: '#FABBCD',
    light: '#FAE8ED',
    medium: '#F5D1DB',
    typography: '#FFFFFF'
  },
  '63ebf65e89706db233f9c925' :{ //Momentom -> colores de performance
    solid: '#E1B03C',
    light: '#F5E4BD',
    medium: '#E8C36A',
    typography: '#FFFFFF'
  }
};