import React from 'react';
import AdaptableForm from '../../../Components/AdaptableForm/AdaptableForm';
import InputText from '../../../Components/InputsForm/InputText';
import InputRichText from '../../../Components/InputsForm/InputRichText';
import InputSelect from '../../../Components/InputsForm/InputSelect';
import { ReferenceArrayInput, required } from 'react-admin';
import InputImage from '../../../Components/InputsForm/InputImage';
import InputButtonAws from '../../../Components/InputsForm/InputButtonAws';
import InputAutocomplete from '../../../Components/InputsForm/InputAutocomplete';
import { VISIBILITY_TYPES } from '../../../assets/constants/BlogsUtils';

const FormBlogs = ({ isEdit }) => {

  const data = [
    {
      title: 'Information',
      inputs: [
        {
          field: <InputText fullWidth source='title' validate={[required()]} />,
          breakpoints: { xs: 6 }
        },
        {
          field: <InputText fullWidth source='subtitle' validate={[required()]} />,
          breakpoints: { xs: 6 }
        },
        {
          field: <InputText fullWidth source='author' validate={[required()]} />,
          breakpoints: { xs: 6 }
        },
        {
          field: <InputText fullWidth source='category' validate={[required()]} />,
          breakpoints: { xs: 6 }
        },
        {
          field: <InputRichText fullWidth source='body' validate={[required()]} />,
          breakpoints: { xs: 12 }
        },
      ]
    },
    {
      title: 'Details',
      inputs: [
        {
          field: <InputText disabled fullWidth source='visibility' defaultValue="Guest"/>,
          breakpoints: { xs: 6 }
        },
        {
          field: <ReferenceArrayInput source='tags' reference='posts/tags'>
            <InputAutocomplete isArray fullWidth optionText={'text'} optionValue={'text'} />
          </ReferenceArrayInput>,
          breakpoints: { xs: 6 }
        },
      ]
    },
    {
      title: 'Image',
      inputs: [
        {
          field: <InputButtonAws source={'picture.original'} validate={[required()]} />,
          breakpoints: { xs: 8 }
        },
      ]
    }
  ];

  return (
    <AdaptableForm
      isEdit={isEdit}
      data={data}
    />
  );
};

export default FormBlogs;