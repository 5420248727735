import React from 'react';
import FormHeros from './FormHeros';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';

const EditHeros = () => {
  return (
    <EditFormCustom>
      <FormHeros isEdit />
    </EditFormCustom>
  );
};

export default EditHeros;