import CreateTalents from './Form/CreateTalents';
import EditTalents from './Form/EditTalents';
import TalentsList from './TalentsList';
import { TbUsers } from 'react-icons/tb';

export default {
  list: TalentsList,
  edit: EditTalents,
  create: CreateTalents,
  icon: TbUsers
};