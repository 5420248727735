import React from 'react';
import { AutocompleteArrayInput, AutocompleteInput } from 'react-admin';
import { INPUTS_VARIANT } from '../../assets/constants/Form';

const InputAutocomplete = ({ isArray, ...rest }) => {
  if (isArray) {
    return (
      <AutocompleteArrayInput variant={INPUTS_VARIANT} {...rest} />
    );
  }

  return (
    <AutocompleteInput variant={INPUTS_VARIANT} {...rest} />
  );
};

export default InputAutocomplete;