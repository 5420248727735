import React from 'react';
import { Edit } from 'react-admin';
import FormTalents from './FormTalents';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';

const EditTalents = () => {

  const transform = (data) => {
    return {
      ...data,
      ['long_bio'] : Array.isArray(data?.['long_bio']) ? data?.['long_bio'][0] : data?.['long_bio'],
    };
  };
  return (
    <EditFormCustom transform={transform}>
      <FormTalents isEdit />
    </EditFormCustom>
  );
};

export default EditTalents;