import React, { useState } from 'react';
import { ImageField, required, DateTimeInput, ReferenceField, ReferenceInput, ReferenceArrayInput, SelectArrayInput, AutocompleteArrayInput } from 'react-admin';
import AdaptableForm from '../../../Components/AdaptableForm/AdaptableForm';
import InputBoolean from '../../../Components/InputsForm/InputBoolean';
import InputText from '../../../Components/InputsForm/InputText';
import InputSelect from '../../../Components/InputsForm/InputSelect';

const FormNotifications = ({ isEdit }) => {
  const [sendNow, setSendNow] = useState(false);

  const data = [
    {
      title: 'Body',
      inputs: [
        {
          field: <InputText
            source='title'
            fullWidth
            validate={[required()]}
          />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputText
            source='body'
            fullWidth
            validate={[required()]}
          />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputText
            source='url'
            fullWidth
          />,
          breakpoints: { xs: 8 }
        },
      ]
    },
    {
      title: 'Schedule',
      inputs: [
        {
          field: <DateTimeInput
            source='send_at'
            disabled={sendNow}
            fullWidth
          />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputBoolean
            source='send_now'
            value={sendNow}
            onChange={() => setSendNow(prev => !prev)}
            fullWidth
          />,
          breakpoints: { xs: 8 }
        },
      ]
    },
    {
      title: 'Filters',
      inputs: [
        {
          field: <ReferenceArrayInput perPage={100} fullWidth label="Filter by talents" source='filter.favourite_talents' reference="talents" >
            <AutocompleteArrayInput fullWidth optionText={'name'} />
          </ReferenceArrayInput>,
          breakpoints: { xs: 8 }
        },
        {
          field: <ReferenceArrayInput perPage={100} fullWidth label="Filter by talents" source='filter.favourite_events' reference="events">
            <AutocompleteArrayInput fullWidth optionText={'name'} />
          </ReferenceArrayInput>,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputBoolean
            source='test'
            fullWidth
          />,
          breakpoints: { xs: 8 }
        }
      ]
    },
  ];

  return (
    <AdaptableForm
      isEdit={isEdit}
      data={data}
    />
  );
};

export default FormNotifications;