import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useInput, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import { convertEndDateToDuration, convertTime, convertToUtc } from '../../helpers/date';
import { customValidationStartDate } from '../CustomValidations/customValidations';
import InputDate from './InputDate';

export const HOURS  = Array.from({length: 25}, (_, index) => index);
export const MINUTES  = Array.from({length: 60}, (_, index) => index);

export const InputDuration = ({label, source, ...rest}) => {
  const {field} = useInput({source});
  const { field: dirtyStart } = useInput({source: 'start_date'});
  const record = useRecordContext();
  const {getValues, setValue} = useFormContext();
  const startDate = getValues('start_date');
  const endDate = getValues('end_date');
  const initialDuration = (endDate && startDate) ? convertEndDateToDuration(startDate, endDate) : null;
  const [duration, setDuration] = useState({
    hours: record?.duration ? HOURS[Math.floor(record?.duration / 60)] : 0, 
    minutes: record ? MINUTES[Math.ceil(record?.duration % 60)] : 15
  });

  useEffect(() => {
    let fullDuration = (duration.hours * 60) + duration.minutes;
    field.onChange(fullDuration);
    let refreshStartDate = getValues('start_date');
    let newEndDate = moment(convertTime(refreshStartDate)).add(fullDuration, 'minutes').format('');
    setValue('end_date', newEndDate);
  },[duration, dirtyStart]);
  
  useEffect(() => {
    let refreshStartDate = getValues('start_date');
    let newEndDate = moment(convertTime(refreshStartDate)).add(record?.duration, 'minutes').format('');
    setValue('end_date', newEndDate);
  },[]);

  return(
    <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '20px', gap: 10}}>
      <Typography>{label}</Typography>
      <Box style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
        <Autocomplete
          disableClearable
          style={{width: 'auto', minWidth: '200px'}}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          value={field?.duration?.hours}
          defaultValue={record?.duration ? HOURS[Math.floor(record?.duration / 60)] : HOURS[0]}
          onChange={(e,v) => setDuration({...duration, hours: v})}
          options={HOURS}
          getOptionLabel={(option) => option}
          renderInput={(params) => <TextField label="Hours" variant="outlined" {...params}/>}
        />
        <Autocomplete
          disableClearable
          style={{width: 'auto', minWidth: '200px'}}
          variant="outlined"
          onChange={(e,v) => setDuration({...duration, minutes: v})}
          value={field?.duration?.minutes}
          defaultValue={record ? MINUTES[Math.ceil(record?.duration % 60)] : MINUTES[15]}
          InputLabelProps={{
            shrink: true
          }}
          label="Minutes"
          options={MINUTES}
          getOptionLabel={(option) => option}
          renderInput={(params) => <TextField label="Minutes" variant="outlined" {...params}/>}
        />
      </Box>
    </Box>

  );
};