import { transformSlug } from '../../../helpers/transforms';

export const transform = (data) => {
  const slug = transformSlug(data?.name);
  return(
    {
      ...data,
      slug
    }
  );
};