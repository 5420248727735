import React from 'react';
import AdaptableForm from '../../../Components/AdaptableForm/AdaptableForm';
import { ImageField } from 'react-admin';
import InputButtonAws from '../../../Components/InputsForm/InputButtonAws';
import InputText from '../../../Components/InputsForm/InputText';

const FormHeros = ({ isEdit }) => {
  const data = [
    {
      title: 'Information',
      inputs: [
        {
          field: <InputText fullWidth source='link' />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputButtonAws source={'picture.original'} />,
          breakpoints: { xs: 8 }
        },
      ]
    }
  ];
  return (
    <AdaptableForm
      isEdit={isEdit}
      data={data}
    />
  );
};

export default FormHeros;