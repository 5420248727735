import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import FormEvents from '../../Resources/Events/Form/FormEvents';
import { EditBase, useNotify } from 'react-admin';
import { transform } from '../../Resources/Events/Form/Transform';

const ModalItemBottom = ({ item }) => {
  const notify = useNotify();

  const onError = ({ ...rest }) => {
    notify(rest?.body?.detail, { type: 'error' });
  };

  return (
    <Box sx={{
      width: '96%',
      maxWidth: '1420px',
      display: 'flex',
      backgroundColor: '#fff',
      border: '1px solid rgba(0,0,0,.2)',
      borderRadius: 2,
      overflow: 'hidden',
      marginTop: 1
    }}>
      <EditBase transform={transform} mutationOptions={{ onError }} id={item.id} mutationMode='pessimistic'>
        <FormEvents isEdit />
      </EditBase>
    </Box>
  );
};

export default ModalItemBottom;