
const useCalculateRow = () => {
  const between = (events, dragTime) => {
    let row = 1;
    for (let i = 0; i < events.length; i++){
      let startDate = new Date(events[i].start_date).getTime();
      let endDate = new Date(events[i].end_date).getTime();
      if(dragTime > startDate && dragTime < endDate) {
        row = row + 1;
        continue;
      }
    }
    return row;
  };

  return between;
};

export default useCalculateRow;