import { useEffect, useState } from 'react';
import { AutocompleteInput, ReferenceInput, useGetList, useInput, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';


const InputMainCategory = ({source}) => {
  const {data} = useGetList('talents/categories');
  const record = useRecordContext();
  const { field, input } = useInput({ source });
  const [categories, setCategories] = useState([]);


  useEffect(() => {
    data && setCategories(data.map(item => {return {id: item.text, text: item.text};}));
  }, [data]);

  return(
    <AutocompleteInput
      onChange={(e,v) => field.onChange(v)}
      source={source}
      variant="outlined"
      optionText={'text'}
      optionValue={'id'}
      choices={categories}
      onCreate={(e) => setCategories([...categories, {id: e, text: e}])}
      label="Main Category"
    />
  );
};

export default InputMainCategory;