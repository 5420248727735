import React, { useState } from 'react';
import AdaptableForm from '../../../Components/AdaptableForm/AdaptableForm';
import InputText from '../../../Components/InputsForm/InputText';
import { ReferenceInput, required, useRecordContext } from 'react-admin';
import InputSelect from '../../../Components/InputsForm/InputSelect';
import { ROLES } from '../../../assets/constants/permissions';
import InputAutocomplete from '../../../Components/InputsForm/InputAutocomplete';


const FormAdmins = ({ isEdit }) => {
  const record = useRecordContext();
  const [showTalentId, setShowTalentId] = useState(record?.role == 'talent');

  const handleChange = (e) => {
    setShowTalentId(e.target.value == 'talent');
  };

  const data = [
    {
      title: 'Information',
      inputs: [
        {
          field: <InputText fullWidth source='name' validate={[required()]} />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputText fullWidth source='email' validate={[required()]} />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputSelect fullWidth source='role' onChange={handleChange} choices={ROLES} validate={[required()]} />,
          breakpoints: { xs: 8 }
        },
        showTalentId && {
          field: <ReferenceInput source="talent_id" reference='talents'>
            <InputAutocomplete optionText="name" fullWidth validate={[required()]} />
          </ReferenceInput>,
          breakpoints: { xs: 8 }
        },
      ]
    },
  ];

  return (
    <AdaptableForm
      isEdit={isEdit}
      data={data}
    />
  );
};

export default FormAdmins;