import React from 'react';
import { Create } from 'react-admin';
import FormBlogs from './FormBlogs';
import { transform } from './Transform';

const CreateBlogs = () => {
  return (
    <Create transform={transform} redirect='list' mutationMode={'pessimistic'}>
      <FormBlogs />
    </Create>
  );
};

export default CreateBlogs;