import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { ImageField, ListBase, useListContext } from 'react-admin';
import { Link } from 'react-router-dom';

const HomeList = ({ ...props }) => {
  return (
    <ListBase {...props}>
      <DatagridImages />
    </ListBase>
  );
};

const DatagridImages = () => {
  const { data, isLoading } = useListContext();
  return (
    <>
      <Typography style={{textTransform: 'uppercase', color: '#606060', fontWeight: 200, margin: '30px 0 0 10px'}} variant='h5'>App Image Disposition</Typography>
      
      <Divider />
      {/* // <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 6 }}> */}
      <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

        {
          data?.map((item, index) => (
            <Box style={{display: 'flex', flexDirection: 'column', margin: '20px'}}>
              <Typography style={{textTransform: 'uppercase', color: '#818181', fontWeight: 200}} variant='h7'>{item.position}</Typography>
              <Link to={`/heroes/${item.id}/edit`} key={`listHeros-${index}`}>
                {/* {item.picture.original} */}
                <img src={item.picture.original} style={{ objectFit: 'contain', maxWidth: '530px' }} />
              </Link>
            </Box>
          ))
        }
      </Box>
    </>
  );
};

export default HomeList;