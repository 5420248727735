import React, { useState } from 'react';
import { BooleanInput, NumberField, NumberInput, ReferenceArrayInput, ReferenceInput, SelectInput, required } from 'react-admin';
import AdaptableForm from '../../../Components/AdaptableForm/AdaptableForm';
import InputText from '../../../Components/InputsForm/InputText';
import InputDate from '../../../Components/InputsForm/InputDate';
import InputAutocomplete from '../../../Components/InputsForm/InputAutocomplete';
import InputButtonAws from '../../../Components/InputsForm/InputButtonAws';
import moment from 'moment';
import { convertTime, convertToUtc } from '../../../helpers/date';
import { InputDuration } from '../../../Components/InputsForm/InputDuration';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { InputSwitchDates } from '../../../Components/InputsForm/InputSwitchDates';
import { customValidationStartDate } from '../../../Components/CustomValidations/customValidations';
import { InputStartingPoint } from '../../../Components/InputsForm/InputStartingPoint';

const FormEvents = ({ isEdit }) => {
  const [runningPoint, setRunningPoint] = useState(false);
  const data = [
    {
      title: 'Event details',
      inputs: [
        {
          field: <InputText
            source="name"
            validate={[required()]}
            fullWidth
          />,
          breakpoints: { xs: 6 }
        },
        {
          field: <ReferenceInput perPage={9999} source="stage.id" reference='venues'>
            <InputAutocomplete optionText="name" label='Stage' fullWidth validate={[required()]} />
          </ReferenceInput>,
          breakpoints: { xs: 6 }
        },
        {
          field: <ReferenceArrayInput perPage={9999} source="talents_ids" reference='talents'>
            <InputAutocomplete isArray optionText='name' sourcefullWidth />
          </ReferenceArrayInput>,
          breakpoints: { xs: 6 }
        },
        {
          field: <InputStartingPoint runningPoint={runningPoint} setRunningPoint={setRunningPoint} source="start_point" />,
          breakpoints: {xs: 6},
          hide: !runningPoint
        },
        {
          field: <ReferenceArrayInput source="collaborators_ids" reference='talents'>
            <InputAutocomplete isArray optionText='name' fullWidth />
          </ReferenceArrayInput>,
          breakpoints: { xs: 6 }
        },
        {
          field: <ReferenceArrayInput source="tags" reference='events/tags'>
            <InputAutocomplete isArray optionValue='text' optionText='text' fullWidth />
          </ReferenceArrayInput>,
          breakpoints: { xs: 6 }
        },
        {
          field: <ReferenceArrayInput source="type" reference='events/types'>
            <InputAutocomplete optionValue={'text'} validate={[required()]} optionText='text' fullWidth />
          </ReferenceArrayInput>,
          breakpoints: { xs: 6 }
        },
        {
          field: <InputText fullWidth source='good_to_know' />,
          breakpoints: { xs: 6 }
        },
        {
          field: <InputText fullWidth source='route' />,
          breakpoints: { xs: 6 }
        },
        // {
        //   field: <SelectInput variant="outlined" choices={[{id: 'Late', name: 'Late'}, {id: 'Early', name: 'Early'}]} fullWidth source='fade_out' />,
        //   breakpoints: { xs: 6 }
        // },
        {
          field: <InputText fullWidth source='distance' />,
          breakpoints: { xs: 6 }
        },
        {
          field: <BooleanInput label="Requires booking" source="requires_booking" />,
          breakpoints: { xs: 6}
        },
        {
          field: <NumberInput fullWidth variant="outlined" source="price" />,
          breakpoints: { xs: 6}
        },
        {
          field: <InputText fullWidth source='booking_url' />,
          breakpoints: { xs: 6 }
        },
        {
          field: <InputText fullWidth source='whats_included' />,
          breakpoints: { xs: 6 }
        },
        {
          field: <NumberInput variant="outlined" fullWidth source='min_age' />,
          breakpoints: { xs: 6 }
        },
        {
          field: <InputText
            source="description"
            multiline
            fullWidth
          />,
          breakpoints: { xs: 12 }
        },
      ]
    },
    {
      title: 'Date',
      inputs: [
        
        {
          field: <InputDuration label="Duration" source="duration"/>,
          breakpoints: {xs: 12}
        },
        // {
        //   field: <InputSwitchDates firstDate={firstDate} enableDates={enableDates} setEnableDates={setEnableDates} />,
        //   breakpoints: {xs: 12}

        // },
        {
          field: <InputDate
            fullWidth
            inputTime
            source='start_date'
            defaultValue={null}
            validate={customValidationStartDate}
            format={(value) => convertTime(value)}
            parse={(value) => convertToUtc(value)}
          />,
          breakpoints: { xs: 6 }
        },
        {
          field: <InputDate
            fullWidth
            inputTime
            disabled
            defaultValue={null}
            source='end_date'
            format={(value) => convertTime(value)}
            parse={(value) => convertToUtc(value)}
          />,
          breakpoints: { xs: 6 }
        },
      ]
    },
    {
      title: 'Image',
      inputs: [
        {
          field: <InputButtonAws source={'picture.original'} validate={[required()]} />,
          breakpoints: { xs: 8 }
        }
      ]
    }
  ];

  return (
    <AdaptableForm
      isEdit={isEdit}
      data={data}
    />
  );
};

export default FormEvents;