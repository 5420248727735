import React from 'react';
import AdaptableForm from '../../../Components/AdaptableForm/AdaptableForm';
import InputText from '../../../Components/InputsForm/InputText';
import { ReferenceArrayInput, ReferenceInput } from 'react-admin';
import InputAutocomplete from '../../../Components/InputsForm/InputAutocomplete';
import InputDate from '../../../Components/InputsForm/InputDate';
import InputBoolean from '../../../Components/InputsForm/InputBoolean';

const FormUsers = ({ isEdit }) => {

  const data = [
    {
      title: 'Information',
      inputs: [
        {
          field: <InputText fullWidth source='id' disabled />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputText fullWidth source='expo_token' disabled />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputText fullWidth source='device_token' disabled />,
          breakpoints: { xs: 8 }
        },
        {
          field: <ReferenceArrayInput source="favourite_talents" reference='talents'>
            <InputAutocomplete optionText="name" fullWidth disabled />
          </ReferenceArrayInput>,
          breakpoints: { xs: 8 }
        },
        {
          field: <ReferenceArrayInput source="favourite_events" reference='events'>
            <InputAutocomplete optionText="name" fullWidth disabled />
          </ReferenceArrayInput>,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputDate inputTime fullWidth source='date_created' disabled />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputText inputTime fullWidth source='app_version' disabled />,
          breakpoints: { xs: 8 }
        },
        {
          field: <InputBoolean fullWidth source='test' />,
          breakpoints: { xs: 8 }
        },
      ]
    },
  ];

  return (
    <AdaptableForm
      isEdit={isEdit}
      data={data}
    />
  );
};

export default FormUsers;