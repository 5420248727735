import React from 'react';
import {
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  ListButtons,
  QuoteButtons,
  ClearButtons,
  AlignmentButtons
} from 'ra-input-rich-text';

const InputRichText = ({ ...rest }) => {
  return (
    <RichTextInput
      toolbar={
        <RichTextInputToolbar>
          <LevelSelect />
          <FormatButtons />
          <AlignmentButtons />
          <ListButtons />
          <QuoteButtons />
          <ClearButtons />
        </RichTextInputToolbar>
      }
      {...rest}
    />
  );
};

export default InputRichText;