import 'react-calendar-timeline/lib/Timeline.css';
import React, { useEffect, useRef, useState } from 'react';
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  CursorMarker,
  CustomHeader,
  DateHeader
} from 'react-calendar-timeline/lib';
// make sure you include the timeline stylesheet or the timeline will not be styled
import moment from 'moment';
import { animateScroll, checkCanMove, formattedItemsCalendar } from '../../helpers/calendar';
import CalendarItem from './CalendarItem';
import { useNotify, useRedirect, useUpdate } from 'react-admin';
import { Box, Button, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import { TimeStepsCalendar, defaultKeys, itemPropsDefaults, styleTitleDate } from '../../assets/constants/Calendar';
import GroupItemCalendar from './GroupItemCalendar';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import ModalItemBottom from './ModalItemBottom';
import ModalAddSecondaryItems from './ModalAddSecondaryItems';
import useCalculateRow from '../../Hooks/useCalculateRow';
import { BiHelpCircle } from 'react-icons/bi';

const CalendarTimeLine = ({ items, secondaryItems, groups, keys = defaultKeys }) => {
  const [itemList, setItemList] = useState([]);
  const [groupList, setGroupList] = useState(groups);
  const [selectedItem, setSelectedItem] = useState(null);
  const [canvasDClicked, setCanvasDClick] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const scrollRef = useRef();
  const notify = useNotify();
  const redirect = useRedirect();
  const [update] = useUpdate();
  const between = useCalculateRow();

  useEffect(() => {
    setGroupList(groups);
  },[groups]);
  const handleClickMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    setItemList(formattedItemsCalendar(items, itemPropsDefaults, keys));
    // setSelectedItem(null);
  }, [items]);

  const defaultTimeStart = (items && items.length > 0) && moment(items[0][defaultKeys.itemTimeStartKey]).subtract(3, 'hours').minute(0).second(0);
  const defaultTimeEnd = defaultTimeStart && moment(defaultTimeStart).add(3, 'hours');

  const handleClickGroup = (group) => {
    if (groupList.length == 1) {
      setGroupList(groups);
    } else {
      setGroupList([group]);
    }
  };

  const handleItemMove = (itemId, dragTime, newGroupOrder) => {
    const moveToVenue = (typeof newGroupOrder == 'number') ? groupList[newGroupOrder].id : newGroupOrder;
    const item = itemList.find(item => item.id === itemId);;
    const canMove = checkCanMove(item, itemList, dragTime, moveToVenue, keys, false);
    // if (canMove) {
    const itemEdit = {
      [keys.itemTimeStartKey]: dragTime,
      [keys.itemTimeEndKey]: dragTime + (item[keys.itemTimeEndKey] - item[keys.itemTimeStartKey]),
      // row: between(itemList, dragTime)
    };
    const itemsListEdit = itemList.map(item => item.id === itemId ? Object.assign({}, item, { ...itemEdit, [keys.itemGroupKey]: moveToVenue }) : item);
    update('events', { id: itemId, data: { ...itemEdit, stage_id: moveToVenue } });
    setItemList(itemsListEdit);
    return true;
    // } else {
    //   notify('You cannot place this event at the same time as another event in the same stage.', { type: 'warning' });
    //   return false;
    // }
  };

  const handleItemResize = (itemId, time, edge) => {
    const item = itemList.find(item => item.id === itemId);
    const moveToVenue = item[keys.itemGroupKey];
    // const canResize = checkCanMove(item, itemList, time, moveToVenue, keys, true);

    // if (canResize) {
    const itemEdit = {
      [keys.itemTimeStartKey]: edge === 'left' ? time : item[keys.itemTimeStartKey],
      [keys.itemTimeEndKey]: edge === 'left' ? item[keys.itemTimeEndKey] : time
    };
    update('events', { id: itemId, data: itemEdit });
    const itemsListEdit = itemList.map(item => item.id === itemId ? Object.assign({}, item, itemEdit) : item);
    setItemList(itemsListEdit);
    // } else {
    //   notify('The duration of this event cannot be extended as it overlaps with another event at the same stage during the same time', { type: 'warning' });
    // }
  };

  const handleItemDoubleClick = (itemId) => {
    redirect(`/events/${itemId}`);
  };

  const onPrevClick = () => {
    animateScroll(scrollRef, true);
  };

  const onNextClick = () => {
    animateScroll(scrollRef, false);
  };

  const handleCanvasDoubleClick = (groupId, time, e) => {
    setCanvasDClick({ groupId, time });
  };

  const isSelectedGroup = (group) => {
    return (groupList.length == 1 && groupList[0].id == group.id);
  };

  const handleItemSelect = (itemId) => {
    const isSelectedGroup = groupList.length == 1;
    if (isSelectedGroup) {
      const item = itemList.find(e => e.id == itemId) ?? null;
      setSelectedItem(item);
    }
  };

  const handleCanvasClick = () => {
    setCanvasDClick(null);
  };

  const handleItemDeselect = () => {
    setSelectedItem(null);
    // setCanvasDClick(null);
  };

  const handleAddPrevItem = (item) => {
    setItemList((prev) => [...prev, item]);
  };

  const [visibleTimeStart, setVisibleTimeStart] = useState(moment(defaultTimeStart).valueOf());
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(moment(defaultTimeEnd).valueOf());
  const defaultTimeRange = defaultTimeEnd - defaultTimeStart;
  const handleTimeChange = (visibleTimeStart, visibleTimeEnd) => {
    setVisibleTimeStart(visibleTimeStart);
    setVisibleTimeEnd(visibleTimeEnd);
  };

  const handleDeletePrevItem = (item) => {
    const itemListFilterItem = itemList.filter(i => i.id !== item.id);
    setItemList(itemListFilterItem);
  };
  
  return (
    <Box>
      {
        items && items.length > 0 && (
          <Timeline
            scrollRef={(e) => (scrollRef.current = e)}
            groups={groupList}
            keys={keys}
            items={itemList}
            itemTouchSendsClick={false}
            itemHeightRatio={.93}
            defaultTimeStart={defaultTimeStart}
            defaultTimeEnd={defaultTimeEnd}
            onTimeChange={handleTimeChange}
            visibleTimeStart={visibleTimeStart}
            visibleTimeEnd={visibleTimeEnd}
            itemRenderer={(props) => <CalendarItem {...props} keys={keys} />}
            timeSteps={TimeStepsCalendar}
            lineHeight={90}
            // maxZoom={365 * 86400 * 4} //que tan lejos puede ver
            // minZoom={60 * 60 * 2000}
            maxZoom={defaultTimeRange}
            minZoom={defaultTimeRange}
            onItemSelect={handleItemSelect}
            onItemDeselect={handleItemDeselect}
            onItemMove={handleItemMove}
            onItemDoubleClick={handleItemDoubleClick}
            onCanvasDoubleClick={handleCanvasDoubleClick}
            groupRenderer={({ group }) => <GroupItemCalendar group={group} selected={isSelectedGroup(group)} handleClick={handleClickGroup} />}
            onItemResize={handleItemResize}
            // onCanvasClick={handleCanvasClick}
            // moveResizeValidator={handleMoveResizeValidator}
            style={{ border: 'none', position: 'relative', zIndex: 100 }}
          >
            <CursorMarker>
              {({ styles }) =>
                <div style={{ ...styles, backgroundColor: 'rgba(0,0,0,.4)' }}/>
              }
            </CursorMarker>

            {/* <TimelineMarkers>
        <TodayMarker>
          {({ styles, date }) =>
            <div style={{ ...styles, backgroundColor: 'red' }} />
          }
        </TodayMarker>
      </TimelineMarkers> */}

            <TimelineHeaders className={'sticky'} sx={{ position: 'sticky', top: 0 }}>
              
              <SidebarHeader variant={'left'}>
                {() => {
                  return (
                    <>
                      <Box style={{ width: '150px', padding: '10px', backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <IconButton aria-describedby='popover' onClick={handleClickMenu}>
                          <BiHelpCircle size={40}/>
                        </IconButton>
                        <Popover 
                          id="popover"
                          open={open}
                          onClose={handleCloseMenu}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        >
                          <Box sx={{
                            maxWidth: '320px',
                            backgroundColor: '#fff',
                            padding: '15px 18px',}}
                          >
                            <Typography variant='subtitle2' style={{fontWeight: '400'}}>
                              <Typography fontSize="20px">How to navigate the calendar</Typography>
                              To quickly schedule events, double-click on a blank space in the canvas (the calendar that contains all events). This action will open a tab displaying all unscheduled events, allowing you to select one and position it at the time where you double-clicked.
                              <br /><br />
Here are some useful keyboard and mouse combinations for navigating the canvas:
                              <br /><br />
Shift + Mousewheel: Move the timeline left or right
Alt + Mousewheel: Zoom in or out
Ctrl + Mousewheel: Zoom in or out 10 times
Additionally, you can click on events and drag them between stages and timelines. Double-clicking on events enables you to edit any information associated with them.
                              <br /><br />
Please note that if you overlap timelines, a new row within the stage will be added to accommodate the new event.
                              <br /><br />
                            You can click a stage to view its calendar, hidding all other stages, and click it again to exit.
                            </Typography>
                          </Box>
                        </Popover>
                      </Box>
                    </>
                  );
                }}
              </SidebarHeader>
              <SidebarHeader variant={'right'}>
                {() => {
                  return <Box style={{ width: 130, backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box>
                      <IconButton onClick={onPrevClick} >
                        <MdNavigateBefore fontSize={36} />
                      </IconButton>
                    </Box>
                    <Box>
                      <IconButton onClick={onNextClick} >
                        <MdNavigateNext fontSize={36} />
                      </IconButton>
                    </Box>

                  </Box>;
                }}
              </SidebarHeader>

              {/* <SidebarHeader variant={'right'}>
            {() => {
              return <Box style={{ width: 240, height: 80, backgroundColor: 'transparent', display: 'flex', gap: 4, flexWrap: 'wrap', padding: '21px 9px 0 9px', justifyContent: 'center', alignItems: 'flex-start' }}>

                <Button
                  variant='text'
                  size='small'
                  startIcon={<MdListAlt style={{ width: '21px' }} />}
                  onClick={() => setViewCalendar(false)}
                >
                  View List
                </Button>

                <Button
                  variant='contained'
                  size='small'
                  startIcon={<FiPlus />}
                  onClick={() => redirect('create/events')}
                >
                  Create
                </Button>
              </Box>;
            }}
          </SidebarHeader> */}

              <CustomHeader height={40} unit="day" className={'sticky'}>
                {({
                  headerContext,
                  getRootProps,
                  showPeriod,
                  getIntervalProps
                }) => {
                  return (
                    <Box className={'sticky'} sx={{
                      ...getRootProps().style,
                      position: 'sticky', top: 0
                    }}
                    >
                      {headerContext.intervals.map(interval => {
                        return (
                          <Box
                            className={'sticky'}
                            onClick={() => {
                              showPeriod(interval.startTime, interval.endTime);
                            }}
                            {...getIntervalProps({ interval, style: styleTitleDate })} sx={{
                              backgroundColor: '#fff',
                              display: 'flex',
                              justifyContent: 'space-between',
                              // '&:hover': {
                              //   backgroundColor: 'rgba(0,0,0,0.03)',
                              // }
                            }}>
                            <Typography
                              variant='h5'
                              sx={{ position: 'sticky', left: 15 }}
                            >
                              {moment(interval.startTime).format('dddd, DD MMMM')}
                            </Typography>
                          </Box>
                        );
                      })}

                      <Button>Create</Button>
                    </Box>
                  );
                }}
              </CustomHeader>
              <CustomHeader height={40} >
                {({
                  headerContext,
                  getRootProps,
                  showPeriod,
                  getIntervalProps
                }) => {
                  return (
                    <Box style={{ ...getRootProps().style, backgroundColor: '#fff' }}>
                      {headerContext.intervals.map(interval => {
                        return (
                          <Box
                            {...getIntervalProps({ interval, style: { ...styleTitleDate, paddingRight: '84px', borderTopLeftRadius: 12, borderTopRightRadius: 12, display: 'flex', alignItems: 'center', justifyContent: 'center' } })}
                            onClick={() => {
                              showPeriod(interval.startTime, interval.endTime);
                            }}
                          >
                            <Typography>
                              {
                                headerContext.unit == 'hour' ?
                                  moment(interval.startTime).format('HH') :
                                  moment(interval.startTime).format('HH:mm')
                              }
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  );
                }}
              </CustomHeader>
              {/* <DateHeader /> */}

            </TimelineHeaders>
            
          </Timeline>
        )
      }
      {
        selectedItem && <ModalItemBottom item={selectedItem} />
      }
      {
        canvasDClicked && (
          <ModalAddSecondaryItems
            items={secondaryItems}
            canvasClicked={canvasDClicked}
            setCanvasClicked={setCanvasDClick}
            handleAddPrevItem={handleAddPrevItem}
            handleDeletePrevItem={handleDeletePrevItem}
            saveItem={handleItemMove}
          />
        )
      }
    </Box>
  );
};




export default CalendarTimeLine;