import { ReferenceArrayInput, required, useInput } from 'react-admin';
import InputAutocomplete from './InputAutocomplete';
import { useForm, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';



export const InputStartingPoint = ({runningPoint, setRunningPoint, source}) => {
  const { getValues } = useFormContext();
  const { field } = useInput({source: 'stage.id'});
  useEffect(() => {
    let newVenue = getValues('stage.id');
    return newVenue === '649ef6d4514dbb1b276ec0b0' ? setRunningPoint(true) : setRunningPoint(false);
  },[field]);

  return(
    <>
      {
        runningPoint ? (
          <>
            <ReferenceArrayInput source={source} reference='events/start_points'>
              <InputAutocomplete optionValue={'text'} validate={runningPoint && [required()]} optionText='text' fullWidth />
            </ReferenceArrayInput>
          </>
        ) : null
      }
    </>
  );
};