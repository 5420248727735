export const COLORS = {
  light: {
    primary: {
      main: '#153B34'
    },
    secondary: {
      main: '#2E7477',
      // main: '#00d2a6'
    }
  },
  dark: {
    primary: {
      main: '#f2f2f2'
    },
    secondary: {
      // main: '#2E7477',
      main: '#00d2a6'
    }
  }
};